import { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { v4 as uuidv4 } from 'uuid';

import Modal from "components/Admin/Modal";

export default function DonationModal({ isOpen, values, close, submit }) {
  const [ isErrorsEnabled, setIsErrorsEnabled ] = useState(false);
  const donatorFieldRef = useRef();
  const textFieldRef = useRef();
  const amountFieldRef = useRef();

  let isEdit = !!values;
  let initialDonator = values?.donator || "";
  let initialAmount = isEdit ? parseInt(values?.amount_in_rub || 0) : "";
  let initialText = values?.style || "";
  let modalTitle = `${isEdit ? "Edit" : "Create"} donation`;
  let saveButtonText = isEdit ? "Save" : "Create";
  
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        donatorFieldRef?.current?.focus();
      }, 200)
    }
  }, [isOpen])
  
  const onClose = () => {
    setIsErrorsEnabled(false);
    close();
  };

  const onSave = async () => {
    const amount = parseInt(amountFieldRef?.current?.value);

    const form = {
      donator: donatorFieldRef?.current?.value,
      text: textFieldRef?.current?.value,
      amount_in_rub: amount,
      amount_in_orig: amount,
      source: "admin",
      currency: "rub",
      external_id: uuidv4(),
    };

    if (!form.donator || !form.text || !form.amount_in_rub) {
      setIsErrorsEnabled(true);
    } else {
      const result = await submit(form, values?.id || null);

      if (result) {
        onClose();
      }
    }
  };
  
  return (
    <div className="goalModal">
      <Modal open={isOpen} onClose={onClose}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={donatorFieldRef}
            error={isErrorsEnabled && !donatorFieldRef?.current?.value}
            autoFocus
            margin="dense"
            id="donator"
            label="Author"
            defaultValue={initialDonator}
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            inputRef={amountFieldRef}
            error={isErrorsEnabled && !amountFieldRef?.current?.value}
            margin="dense"
            id="amount"
            label="Amount"
            defaultValue={initialAmount}
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
          />
          <TextField
            inputRef={textFieldRef}
            error={isErrorsEnabled && !textFieldRef?.current?.value}
            id="text"
            label="Message"
            defaultValue={initialText}
            margin="dense"
            type="text"
            fullWidth
            variant="standard"
            multiline
            maxRows={5}
            minRows={1}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>{saveButtonText}</Button>
        </DialogActions>
      </Modal>
    </div>
  );
}