import { useState } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';

import GoalService from "services/GoalService";
import GoalModal from "components/Admin/SettingsBlock/GoalsSection/GoalModal";
import { DEFAULT_GOAL_STYLE_NAME } from "components/Admin/SettingsBlock/SettingsBlock.constants";
import { TOKEN } from "constants/index";

export default function GoalsSection({ goals, setGoals }) {
  const hasLastGoal = goals.length === 1;

  const refreshGoals = async () => {
    const result = await GoalService.getAll();
    setGoals(result);
  };

  const createOrUpdateGoal = async (goalData, goalId = null) => {
    let result = null;

    if (goalId) {
      result = await GoalService.updateById(goalId, goalData);
    } else {
      result = await GoalService.create(goalData);
    }
    
    if (result) {
      refreshGoals();
    }

    return result;
  };
  
  const removeGoal = async (id) => {
    if (!hasLastGoal) {
      const result = await GoalService.removeById(id);
    
      if (result) {
        refreshGoals();
      }
    }
  };

  const updateGoalCurrent = async (id, current) => {
    const parsedCurrent = parseInt(current);
    const result = await GoalService.updateGoalCurrentSum(id, parsedCurrent);
    
      if (result) {
        refreshGoals();
      }
  }

  const [goalModalState, setGoalModalState] = useState({ isOpen: false, values: null });

  const openGoalModal = (goalValues = null) => {
    setGoalModalState({ isOpen: true, values: goalValues });
  };

  const closeGoalModal = () => {
    setGoalModalState({ isOpen: false, values: null });
  };

  const goalModalProps = {
    isOpen: goalModalState.isOpen, 
    values: goalModalState.values, 
    close: closeGoalModal, 
    submit: createOrUpdateGoal,
    updateCurrent: updateGoalCurrent,
  };

  const goalsComponents = goals.map(goal => {
    const urlParams = new URLSearchParams({ access_token: TOKEN, goal_id: goal.id })
    const goalLink = `/goal?${urlParams}`;
    const donationLink = `/donation?${urlParams}`;

    return (
      <div className="settingsItem settingsItem_goal" key={goal.id}>
        <div className="settingsItem-info">
          <div className="settingsItem-labels">
            <p>Name:</p>
            <p>Max limit:</p>
            <p>Current:</p>
            <p>Style:</p>
          </div>
          <div className="settingsItem-values">
            <p>{goal.name}</p>
            <p>{parseInt(goal.max_limit)}</p>
            <p>{parseInt(goal.current)}</p>
            <p>{goal.style || DEFAULT_GOAL_STYLE_NAME}</p>
          </div>
          <div className="settingsItem-linkButtons">
            <Tooltip title="Goal Widget" arrow placement="right">
              <IconButton
                href={goalLink}
                target="_blank"
                color="gainsboro"
                className="donationsCol-button"
                size="small"
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Animations Widget" arrow placement="right">
              <IconButton
                href={donationLink}
                target="_blank"
                color="gainsboro"
                className="donationsCol-button"
                size="small"
              >
                <PhotoFilterIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="settingsItem-editButton">
          <Button 
            onClick={() => openGoalModal(goal)}
            variant="outlined" 
            color={"gold"}
            endIcon={<EditNoteIcon />}
          >
            Edit goal
          </Button>
        </div>
        <div className="settingsItem-removeButton">
          <Button 
            onClick={() => removeGoal(goal.id)}
            disabled={hasLastGoal}
            variant="outlined" 
            color={hasLastGoal ? "gainsboro" : "red"}
            endIcon={<DeleteForeverIcon />}
          >
            Remove goal
          </Button>
        </div>
      </div>
    )
  });

  return (
    <div className="settingsSection settingsSection_goals">
      <h2 className="settingsSection-title">
        Donate goals
        <IconButton
          onClick={() => openGoalModal()}
          color="seagreen"
          className="settingsSection-addButton"
          size="small"
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </h2>
      <div className="settingsSection-items">
        { goalsComponents }
      </div>
      <GoalModal { ...goalModalProps }/>
    </div>
  );
}
