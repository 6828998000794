import { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Modal from "components/Admin/Modal";

export default function RuleModal({ isOpen, values, close, submit, animations }) {  
  const [ isErrorsEnabled, setIsErrorsEnabled ] = useState(false);
  const amountFieldRef = useRef();
  const isEqualFieldRef = useRef();
  const animationFieldRef = useRef();

  let isEdit = !!values;
  let initialAmount = parseInt(values?.amount || 0) || "";
  let initialIsEqual = values?.is_equal || false;
  let initialAnimation = values?.animation || (animations.length ? animations[0] : undefined);
  let modalTitle = `${isEdit ? "Edit" : "Create"} donation rule`;
  let saveButtonText = isEdit ? "Save" : "Create";
  
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        amountFieldRef?.current?.focus();
      }, 200)
    }
  }, [isOpen])
  
  const onClose = () => {
    setIsErrorsEnabled(false);
    close();
  };

  const onSave = async () => {
    const form = {
      amount: parseInt(amountFieldRef?.current?.value),
      is_equal: isEqualFieldRef?.current?.checked || false,
      animation: animationFieldRef?.current?.value || (animations.length ? animations[0] : undefined),
    };

    if (Object.values(form).some(val => !val && val !== false)) {
      setIsErrorsEnabled(true);
    } else {
      const result = await submit(form, values?.id || null);

      if (result) {
        onClose();
      }
    }
  };

  if (!animations.length) {
    return (
      <div className="ruleModal">
        <Modal open={isOpen} onClose={onClose}>
          <DialogTitle>You should upload at least one animation first</DialogTitle>
          <DialogContent />
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Modal>
      </div>
    )
  }
  
  return (
    <div className="ruleModal">
      <Modal open={isOpen} onClose={onClose}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={amountFieldRef}
            error={isErrorsEnabled && !amountFieldRef?.current?.value}
            margin="dense"
            id="max_limit"
            label="Min amount"
            defaultValue={initialAmount}
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
          />
          <TextField
            inputRef={animationFieldRef}
            error={isErrorsEnabled && !animationFieldRef?.current?.value}
            id="style"
            select
            label="Style"
            defaultValue={initialAnimation}
            margin="dense"
            fullWidth
            variant="standard"
          >
            {animations.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel 
            control={<Checkbox defaultChecked={initialIsEqual} inputRef={isEqualFieldRef} />} 
            label="Is equal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>{saveButtonText}</Button>
        </DialogActions>
      </Modal>
    </div>
  );
}