import { formatDate } from "common/utils";
import Chip from "@mui/material/Chip";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { DonationSourceNameMap } from "components/Admin/DonationsBlock/DonationsBlock.constants";

import "./Donation.scss";

export default function Donation({
  children,
  donator,
  text,
  amount_in_rub,
  created_at,
  is_good,
  source,
}) {
  const formattedDate = formatDate(created_at);
  const donationClassname = `donation${is_good === false ? " donation_warn" : ""}`;
  const amount = parseInt(amount_in_rub);
  const sourceName = DonationSourceNameMap.get(source) || source || "???";

  return (
    <div className={donationClassname}>
      <div className="donation-title">
        <h4 className="donation-author">
          { is_good === false ? (<WarningAmberIcon className="donation-warnIcon" color="orange" size="small"/>) : "" }
          { donator || (<i>ANONYM</i>) }
        </h4>
        <div className="donation-amount">
          <Chip label={amount} color="gold" size="small" icon={<CurrencyRubleIcon />} variant="outlined" />
        </div>
      </div>
      <p className="donation-date">{ formattedDate }&nbsp;&nbsp;|&nbsp;&nbsp;{sourceName}</p>
      <p className="donation-text">{ text  || (<i>NO MESSAGE</i>) }</p>
      <div className="donation-buttons">{ children }</div>
    </div>
  );
}