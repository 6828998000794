export const BACKEND_URL = "https://svtv-donco-ke2oot.lndyn.co";
export const WS_URL = "wss://svtv-donco-ke2oot.lndyn.co/api/ws";

export const TOKEN = (new URLSearchParams(document.location.search)).get("access_token");
export const GOAL_ID = (new URLSearchParams(document.location.search)).get("goal_id");

export const HEADERS = { "Content-Type": "application/json", "accept": "application/json" };

export const DEFAULT_ANIMATION_HEIGHT = "230";

export const MINIMAL_GOAL_TRANSITION_MS = 450;
