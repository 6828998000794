import { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";

import Modal from "components/Admin/Modal";
import { DEFAULT_ANIMATION_HEIGHT } from "constants/index";

import "./AnimationUploadModal.scss";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function AnimationUploadModal({ isOpen, close, submit }) {
  const [ isErrorsEnabled, setIsErrorsEnabled ] = useState(false);
  const [file, setFile] = useState(null);

  const nameFieldRef = useRef();
  const durationFieldRed = useRef();
  const heightFieldRed = useRef();
  
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        nameFieldRef?.current?.focus();
      }, 200)
    }
  }, [isOpen])
  
  const onClose = () => {
    setIsErrorsEnabled(false);
    close();
  };

  const removeFile = () => {
    setFile(null);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const onSave = async () => {
    const name = nameFieldRef?.current?.value;
    const duration = durationFieldRed?.current?.value;
    const height = heightFieldRed?.current?.value;

    if (file && name && duration) {
      const form = {
        name,
        file,
        duration: +duration,
        height: height ? +height : DEFAULT_ANIMATION_HEIGHT,
      };

      const result = await submit(form);

      if (result) {
        onClose();
      }
    } else {
      setIsErrorsEnabled(true);
    }
  };
  
  return (
    <div className="animationUploadModal">
      <Modal open={isOpen} onClose={onClose}>
        <DialogTitle>
          Upload a new animation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={nameFieldRef}
            error={isErrorsEnabled && !nameFieldRef?.current?.value}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            inputProps={{ pattern: "[a-z0-9_]+" }}
            fullWidth
            variant="standard"
          />
          <TextField
            inputRef={durationFieldRed}
            error={isErrorsEnabled && !durationFieldRed?.current?.value}
            margin="dense"
            id="duration"
            label="Duration (ms)"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
          />
          <TextField
            inputRef={heightFieldRed}
            margin="dense"
            id="height"
            label="Height (px)"
            type="text"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            fullWidth
            variant="standard"
          />
          <p />
          <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
          { file ? (
            <p className="animationUploadModal-fileText">
              {file?.name}&nbsp;&nbsp;
              <IconButton 
                size="small"
                onClick={removeFile}
              >
                <ClearIcon />
              </IconButton>
            </p>
          ):(
            ""
          )}
                
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSave}>Save</Button>
        </DialogActions>
      </Modal>
    </div>
  );
}