import { useState } from "react";
import { isMobile } from "react-device-detect";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { DonationFolders } from "components/Admin/DonationsBlock/DonationsBlock.constants";
import Donation from "components/Admin/DonationsBlock/Donation/Donation";
import DonationService from "services/DonationService";
import useWebSocket from "common/useWebSocket";
import DonationModal from "components/Admin/DonationsBlock/DonationModal/DonationModal";
import SortingTools from "components/Admin/DonationsBlock/SortingTools";
import { DonationSortingStates } from "components/Admin/DonationsBlock/DonationsBlock.constants";
import { sortByTimeAsc, sortByTimeDesc, sortByAmountDesc } from "common/utils";

import "./DonationsBlock.scss";

const { NEW, READING, ARCHIVED } = DonationFolders;
const { TIME_ASC, TIME_DESC, TIME_DESC_ALT } = DonationSortingStates;

export default function DonationsBlock({ state, handlers: reducerHandlers, initialFolder }) {
  const [donationModalState, setDonationModalState] = useState({ isOpen: false, values: null });
  
  const openDonationModal = (values = null) => {
    setDonationModalState({ isOpen: true, values });
  };

  const closeDonationModal = () => {
    setDonationModalState({ isOpen: false, values: null });
  };
  
  useWebSocket("new", (donation) => {
    reducerHandlers.addNewDonation(donation);
  });

  useWebSocket("validate", (donation) => {
    reducerHandlers.updateDonation(donation);
  });

  useWebSocket("read", (donation) => {
    reducerHandlers.updateDonation(donation);
  });

  const [isSuccessAlertOpen, setSuccessAlertOpen] = useState(false);
  const [folder, setFolder] = useState(initialFolder || NEW);

  const showNextFolder = () => {
    const nextFolder = folder === NEW ? READING : NEW;
    setFolder(nextFolder);
  };

  const showPreviousFolder = () => {
    const previousFolder = folder === NEW ? READING : NEW;
    setFolder(previousFolder);
  };

  const openSuccessAlert = () => {
    setSuccessAlertOpen(true);
  };

  const closeSuccessAlert = () => {
    setSuccessAlertOpen(false);
  };

  const showOnDisplay = (id) => async () => {
    await DonationService.resend(id);
    openSuccessAlert();
  };

  const createOrUpdateDonation = async (donationData, donationId = null) => {
    let result = null;

    if (donationId) {
      // result = await GoalService.updateById(goalId, goalData);
    } else {
      result = await DonationService.create(donationData);
    }

    return result;
  };

  const donationModalProps = {
    isOpen: donationModalState.isOpen, 
    values: donationModalState.values, 
    close: closeDonationModal, 
    submit: createOrUpdateDonation,
  };

  const [isNewSearchActive, setIsNewSearchActive] = useState(false);
  const [isReadingSearchActive, setIsReadingSearchActive] = useState(false);
  const [isArchiveSearchActive, setIsArchiveSearchActive] = useState(false);

  const [newSearchState, setNewSearchState] = useState(null);
  const [readingSearchState, setReadingSearchState] = useState(null);
  const [archiveSearchState, setArchiveSearchState] = useState(null);

  const [newSortState, setNewSortState] = useState(TIME_ASC);
  const [readingSortState, setReadingSortState] = useState(TIME_ASC);
  const [archiveSortState, setArchiveSortState] = useState(TIME_DESC_ALT);

  const moveToReadingAsVerified = async(id) => {
    await DonationService.moveToReading(id);

    if (newSearchState?.length) {
      setNewSearchState(state => state.filter(el => el.id !== id));
    }
  };

  const moveToReadingAsWarned = async(id) => {
    await DonationService.moveToReading(id, true);

    if (newSearchState?.length) {
      setNewSearchState(state => state.filter(el => el.id !== id));
    }
  };
  
  const moveToArchive = async(id) => {
    await DonationService.moveToArchive(id);

    if (readingSearchState?.length) {
      setReadingSearchState(state => state.filter(el => el.id !== id));
    }
  };
  
  const returnFromArchive = async(id) => {
    await DonationService.returnFromArchive(id);

    if (archiveSearchState?.length) {
      setArchiveSearchState(state => state.filter(el => el.id !== id));
    }
  };

  const deleteForever = async(id) => {
    await DonationService.removeById(id);
    reducerHandlers.removeDonation(id);
  }

  const newSortingToolsProps = {
    sortState: newSortState,
    setSortState: setNewSortState,
    isSearchActive: isNewSearchActive,
    setIsSearchActive: setIsNewSearchActive,
    searchState: newSearchState,
    setSearchState: setNewSearchState,
    folder: NEW,
  };

  const readingSortingToolsProps = {
    sortState: readingSortState,
    setSortState: setReadingSortState,
    isSearchActive: isReadingSearchActive,
    setIsSearchActive: setIsReadingSearchActive,
    searchState: readingSearchState,
    setSearchState: setReadingSearchState,
    folder: READING,
  };

  const archiveSortingToolsProps = {
    sortState: archiveSortState,
    setSortState: setArchiveSortState,
    isSearchActive: isArchiveSearchActive,
    setIsSearchActive: setIsArchiveSearchActive,
    searchState: archiveSearchState,
    setSearchState: setArchiveSearchState,
    folder: ARCHIVED,
  };

  const newSortFunc = newSortState === TIME_ASC ? sortByTimeAsc(true) : newSortState === TIME_DESC ? sortByTimeDesc(true) : sortByAmountDesc;
  const readingSortFunc = readingSortState === TIME_ASC ? sortByTimeAsc() : readingSortState === TIME_DESC ? sortByTimeDesc() : sortByAmountDesc;
  const archiveSortFunc = archiveSortState === TIME_ASC ? sortByTimeAsc(true) : archiveSortState === TIME_DESC_ALT ? sortByTimeDesc() : archiveSortState === TIME_DESC ? sortByTimeDesc(true) : sortByAmountDesc;

  const searchResultTitle = (<h3 className="donationsCol-searhResultTitle">Search results:</h3>);
  const searchNothingFound = (<p className="donationsCol-searhNotFoundTitle">Nothing found</p>);

  const newDonations = (newSearchState?.length ? newSearchState : state.filter(el => el.status === NEW))
    .sort(newSortFunc)
    .map(({ id, ...props}) => (
      <Donation key={id} {...props}>
        <Button 
          onClick={() => moveToReadingAsWarned(id)}
          variant="outlined" 
          endIcon={<ReportGmailerrorredIcon />}
          color="orange"
        >
          Send as warned
        </Button>
        <Button 
          onClick={() => moveToReadingAsVerified(id)}
          variant="outlined" 
          endIcon={<ArrowForwardIcon />}
          color="seagreen"
        >
          Send as verified
        </Button>
      </Donation>
    ));
  
  const readingDonations = (readingSearchState?.length ? readingSearchState : state.filter(el => el.status === READING))
    .sort(readingSortFunc)
    .map(({ id, ...props}) => (
      <Donation key={id} {...props}>
        <Button 
          onClick={showOnDisplay(id)}
          variant="outlined" 
          endIcon={<CastConnectedIcon />}
          color="gainsboro"
        >
          Show on screen
        </Button>
        <Button 
          onClick={() => moveToArchive(id)}
          variant="outlined" 
          endIcon={<ArrowForwardIcon />}
          color="seagreen"
        >
          Move to archive
        </Button>
      </Donation>
    ));

  const archivedDonations = (archiveSearchState?.length ? archiveSearchState : state.filter(el => el.status === ARCHIVED))
    .sort(archiveSortFunc)
    .map(({ id, ...props}) => (
      <Donation key={id} {...props}>
        <Button 
          onClick={showOnDisplay(id)}
          variant="outlined" 
          endIcon={<CastConnectedIcon />}
          color="gainsboro"
        >
          Show on screen
        </Button>
        <Button 
          onClick={() => deleteForever(id)}
          variant="outlined" 
          endIcon={<DeleteForeverIcon />}
          color="red"
        >
          Delete forever
        </Button>
        <Button 
          onClick={() => returnFromArchive(id)}
          variant="outlined" 
          startIcon={<ArrowBackIcon />}
          color="seagreen"
        >
          Return to reading
        </Button>
      </Donation>
    ));

  return (
    <div className={`donationsBlock ${folder === ARCHIVED ? "donationsBlock_archive" : ""}`}>
      <div className="donationBlock-arrows">
        <IconButton
          onClick={showPreviousFolder}
          color="gainsboro"
        >
          <ArrowBackIcon />
        </IconButton>
        <IconButton
          onClick={showNextFolder}
          color="gainsboro"
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
      { (!isMobile && folder !== ARCHIVED) || folder === NEW ? (
          <div className="donationsCol donationsCol_first">
            <h3 className="donationsCol-title">
              Unverified
              <IconButton
                onClick={() => openDonationModal(null)}
                color="seagreen"
                className="donationsCol-button"
                size="small"
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </h3>
            <SortingTools { ...newSortingToolsProps } />
            { newSearchState ? searchResultTitle : "" }
            { (newSearchState && !newSearchState?.length) ? searchNothingFound : "" }
            <div className="donationsCol-donations">{ newDonations }</div>
          </div>
        ) : ("")
      }
      { (!isMobile && folder !== ARCHIVED) || folder === READING ? (
          <div className="donationsCol">
            <h3 className="donationsCol-title">Reading</h3>
            <SortingTools { ...readingSortingToolsProps } />
            { readingSearchState ? searchResultTitle : "" }
            { readingSearchState && !readingSearchState?.length ? searchNothingFound : "" }
            <div className="donationsCol-donations">{ readingDonations }</div>
          </div>
        ) : ("")
      }
      
      { folder === ARCHIVED ? (
          <div className="donationsCol">
            <h3 className="donationsCol-title donationsCol-title_archived">Archived</h3>
            <SortingTools { ...archiveSortingToolsProps } />
            { archiveSearchState ? searchResultTitle : "" }
            { archiveSearchState && !archiveSearchState?.length ? searchNothingFound : "" }
            <div className="donationsCol-donations">{ archivedDonations }</div>
          </div>
        ) : ("")
      }
      <div className="donationsAlert">
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={isSuccessAlertOpen}
          onClose={closeSuccessAlert}
          message="Success!"
          color="green"
          autoHideDuration="800"
        />
      </div>
      <DonationModal { ...donationModalProps }/>
    </div>
  );
}
