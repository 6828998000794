import { useEffect } from "react";

import { DEFAULT_ANIMATION_HEIGHT } from "constants/index";

import "./DonationAlert.scss";

export default function DonationAlert({ donation, animations, height }) {  
  const { animation, donator, is_good, amount_in_rub } = donation || {};
  const sum = new Intl.NumberFormat('ru-RU').format(parseInt(amount_in_rub));
  const donationAnimation = animation ? animations[animation] : "";

  useEffect(() => {
    const animationElement = document.getElementById('animation');
    animationElement.src = animationElement.getAttribute('src');
  }, [donation]);

  const animationStyle = { height: `${height || DEFAULT_ANIMATION_HEIGHT}px`};

  return (
    <div className="donationAlert">
      <div className="animation">
        <img src={donationAnimation?.url} style={animationStyle} id="animation" alt="" />
      </div>
      {
        donation?.amount_in_rub ? (
          <div className="message">
          <h4 className="message-author">{is_good ? donator : "😎😎😎"}</h4>
          <h3 className="message-sum">{sum} ₽</h3>
        </div>
        ) : (
          ""
        )
      }
    </div>
  );
}
