import Button from "@mui/material/Button";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';

import RuleService from "services/RuleService";
import RuleModal from "components/Admin/SettingsBlock/RulesSection/RuleModal";

export default function RulesSection({ rules, setRules, animations }) {
  const refreshRules = async () => {
    const result = await RuleService.getAll();
    setRules(result);
  }

  const createOrUpdateRule = async (ruleData, ruleId = null) => {
    let result = null;

    if (ruleId) {
      result = await RuleService.updateById(ruleId, ruleData);
    } else {
      result = await RuleService.create(ruleData);
    }
    
    if (result) {
      refreshRules();
    }

    return result;
  };
  
  const removeRule = async (id) => {
    const result = await RuleService.removeById(id);
  
    if (result) {
      refreshRules();
    }
  };

  const [ruleModalState, setRuleModalState] = useState({ isOpen: false, values: null });

  const openRuleModal = (ruleValues = null) => {
    setRuleModalState({ isOpen: true, values: ruleValues });
  };

  const closeRuleModal = () => {
    setRuleModalState(state => ({ ...state, isOpen: false }));
    setTimeout(() => { setRuleModalState({ isOpen: false, values: null }) }, 500);
  };

  const animationNames = animations.map(({ id }) => id);

  const ruleModalProps = {
    isOpen: ruleModalState.isOpen, 
    values: ruleModalState.values, 
    close: closeRuleModal, 
    submit: createOrUpdateRule,
    animations: animationNames,
  };

  const rulesComponents = rules.map(rule => (
    <div className="settingsItem settingsItem_rule" key={rule.id}>
      <div className="settingsItem-info">
        <div className="settingsItem-labels">
          <p>Amount:</p>
          <p>Is equal:</p>
          <p>Animation:</p>
        </div>
        <div className="settingsItem-values">
          <p>{parseInt(rule.amount)}</p>
          <p>{rule.is_equal ? "Yes" : "No"}</p>
          <p>{rule.animation || ""}</p>
        </div>
      </div>
      <div className="settingsItem-editButton">
        <Button 
          onClick={() => openRuleModal(rule)}
          variant="outlined" 
          color={"gold"}
          endIcon={<EditNoteIcon />}
        >
          Edit rule
        </Button>
      </div>
      <div className="settingsItem-removeButton">
        <Button 
          onClick={() => removeRule(rule.id)}
          variant="outlined"
          color={"red"}
          endIcon={<DeleteForeverIcon />}
        >
          Remove rule
        </Button>
      </div>
    </div>
  ));

  return (
    <div className="settingsSection settingsSection_rules">
      <h2 className="settingsSection-title">
        Animation rules
        <IconButton
          onClick={() => openRuleModal()}
          color="seagreen"
          className="settingsSection-addButton"
          size="small"
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </h2>
      <div className="settingsSection-items">
        { rulesComponents }
      </div>
      <RuleModal { ...ruleModalProps } />
    </div>
  );
}
