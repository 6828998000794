
import { useRef } from "react";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

import DonationService from "services/DonationService";
import { DonationSortingStates } from "components/Admin/DonationsBlock/DonationsBlock.constants";
import { DonationFolders } from "components/Admin/DonationsBlock/DonationsBlock.constants";

import "./SortingTools.scss";

const { TIME_DESC, TIME_DESC_ALT, TIME_ASC, AMOUNT_DESC } = DonationSortingStates;

export default function SortingTools({
  sortState,
  setSortState,
  isSearchActive,
  setIsSearchActive,
  setSearchState,
  folder,
}) {
  const searchFieldRef = useRef();

  const setSortStateToTimeAsc = () => setSortState(TIME_ASC);
  const setSortStateToTimeDesc = () => setSortState(TIME_DESC);
  const setSortStateToTimeDescAlt = () => setSortState(TIME_DESC_ALT);
  const setSortStateToAmountDesc = () => setSortState(AMOUNT_DESC);

  const timeAscButtonClass = `sortingTools-button ${sortState === TIME_ASC ? "sortingTools-button_active" : ""}`
  const timeDescButtonClass = `sortingTools-button ${sortState === TIME_DESC ? "sortingTools-button_active" : ""}`
  const timeDescAltButtonClass = `sortingTools-button ${sortState === TIME_DESC_ALT ? "sortingTools-button_active" : ""}`
  const amountDescButtonClass = `sortingTools-button ${sortState === AMOUNT_DESC ? "sortingTools-button_active" : ""}`

  const startSearch = async (searchQuery) => {
    setSearchState(null);
    const res = await DonationService.search(searchQuery, folder);
    
    if (res && res?.length) {
      setTimeout(() => {
        setSearchState(res);
      }, 400);
    }
  };

  const openSearchField = () => {
    setIsSearchActive(true);
    
    setTimeout(() => {
      searchFieldRef?.current?.focus();
    }, 200);
  };
  
  const closeSearchField = () => {
    setIsSearchActive(false);
    setSearchState(null);
  };

  const onTextFieldChange = (e) => {
    if (e.keyCode === 13) {
      startSearch(e.target.value);
      searchFieldRef?.current?.blur();
    }
  }

  const onStartSearchClick = () => {
    startSearch(searchFieldRef?.current?.value);
  };

  return (
    <div className="sortingTools">
      { !isSearchActive ? (
        <>
          <div className="sortingTools_sortButtons">
            {
              folder === DonationFolders.ARCHIVED ? (
                <button 
                  className={timeDescAltButtonClass}
                  onClick={setSortStateToTimeDescAlt}
                >Last updated</button>
              ) : ""
            }
            <button 
              className={timeAscButtonClass}
              onClick={setSortStateToTimeAsc}
            >Earliest first</button>
            <button 
              className={timeDescButtonClass}
              onClick={setSortStateToTimeDesc}
            >Latest first</button>
            <button 
              className={amountDescButtonClass}
              onClick={setSortStateToAmountDesc}
            >Biggest first</button>
          </div>
          <div className="sortingTools_searchButtonBox">
            <IconButton
              onClick={openSearchField}
              color="gainsboro"
              className="sortingTools_searchButton"
              size="small"
            >
              <SearchIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <div className="sortingTools_search">
          <div className="sortingTools_searchFieldBox">
          <TextField
            id="search"
            className="sortingTools_searchField"
            inputRef={searchFieldRef}
            onKeyDown={onTextFieldChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    className="sortingTools_searchFieldIcon"
                    color="gainsboro" 
                    size="small"
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={onStartSearchClick}
                    color="blue"
                    className="sortingTools_searchStartButton"
                    size="small"
                  >
                    <ArrowForwardIcon />
                  </IconButton>
                </>
              )
            }}
            color="gainsboro"
            variant="outlined"
            size="small"
          />
          </div>
          <div className="sortingTools_searchCloseButtonBox">
            <IconButton
              onClick={closeSearchField}
              color="red"
              className="sortingTools_searchCloseButton"
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}