import useWebSocket from "common/useWebSocket";
import { useEffect, useState, useRef } from "react";

import Widget from "components/Widget";
import GoalLine from "components/Widget/GoalWidget/GoalLine";
import { calculateCurrentTransition, wait } from "common/utils";

function GoalWidgetBase({ goal, updateGoal = () => {} }) {
  const [ currentTransitionMs, setCurrentTransitionMs ] = useState(null);
  const [isUpdating, setIsUpdatingState] = useState(false);
  const isUpdatingRef = useRef(false);
  const goalUpdatesRef = useRef([]);
  const savedGoalCurrentNumberRef = useRef(null);

  const setIsUpdating = (val) => {
    setIsUpdatingState(val);
    isUpdatingRef.current = val;
  };

  const onDonation = async (donation) => {
    if (donation.amount_in_rub === undefined) {
      updateGoal(donation)
      return
    }

    if (!donation.is_resent) {
      if (isUpdatingRef.current) {
        goalUpdatesRef.current = [ ...goalUpdatesRef.current, donation ];
      } else {
        goalUpdatesRef.current = goalUpdatesRef.current.slice(1);
        const goalCurrent = savedGoalCurrentNumberRef.current || +goal.current;

        const transitionMs = calculateCurrentTransition(+donation.amount_in_rub || 0);
        const numberUpdateTimes = Math.round(transitionMs / 15);

        setIsUpdating(true);
        setCurrentTransitionMs(transitionMs / (3 + (+donation.amount_in_rub / 30000)));
        
        for (let i = 1; i < numberUpdateTimes; i++) {
          const goalTempCurrentNumber = goalCurrent + Math.round(+donation.amount_in_rub / numberUpdateTimes * i);
          updateGoal({ current: goalTempCurrentNumber });
          await wait(Math.round(transitionMs / numberUpdateTimes));
        }

        const updatedGoalCurrent = goalCurrent + Math.round(+donation.amount_in_rub);
        updateGoal({ current: updatedGoalCurrent });
        savedGoalCurrentNumberRef.current = updatedGoalCurrent;

        await wait(50);
        setIsUpdating(false);
        setCurrentTransitionMs(null);
      }
    }
  }
  
  useWebSocket(`goal/${goal?.id}`, onDonation);

  useEffect(() => {
    if (!isUpdating && goalUpdatesRef.current.length) {
      onDonation(goalUpdatesRef.current[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdating]);

  return (
    <GoalLine
      title={goal.name}
      maxLimit={goal.max_limit}
      current={goal.current}
      currentTransitionMs={currentTransitionMs}
    />
  );
}

export default function GoalWidget() {
  return <Widget><GoalWidgetBase /></Widget>
}