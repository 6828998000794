import { formatInTimeZone } from "date-fns-tz";
import { MINIMAL_GOAL_TRANSITION_MS } from "constants/index";

export const formatDate = (utcString) => {
  const timeZone = 'Europe/Moscow';
  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateString = `${formatInTimeZone(utcString, timeZone, "dd MMM yy, HH:mm")} (MSK)`;

  return dateString;
}  

export const wait = async (timeout) => new Promise(res => setTimeout(() => res(), timeout));

export const sortByTimeAsc = (isCreateTime = false) => {
  const sortProp = isCreateTime ? "created_at" : "updated_at";
  const sortFunc = (a, b) => a[sortProp] < b[sortProp] ? -1 : 1;
  return sortFunc;
}

export const sortByTimeDesc = (isCreateTime = false) => {
  const sortProp = isCreateTime ? "created_at" : "updated_at";
  const sortFunc = (a, b) => a[sortProp] < b[sortProp] ? 1 : -1;
  return sortFunc;
}

export const sortByAmountDesc = (a, b) => +a.amount_in_rub < +b.amount_in_rub ? 1 : -1;

export const calculateCurrentTransition = (amount) => {
  const minimalAmountToSlowdown = 5000;
  
  if (amount <= minimalAmountToSlowdown) {
    return MINIMAL_GOAL_TRANSITION_MS;
  }

  return Math.floor(MINIMAL_GOAL_TRANSITION_MS + MINIMAL_GOAL_TRANSITION_MS * (amount / minimalAmountToSlowdown / 3));
}