import { useEffect, useState, useRef } from "react";
import useWebSocket from "common/useWebSocket";

import Widget from "components/Widget";
import DonationAlert from "components/Widget/DonationWidget/DonationAlert";
import AnimationService from "services/AnimationService";
import { wait } from "common/utils";
import { DEFAULT_ANIMATION_HEIGHT } from "constants/index";

function DonationWidgetBase({ goal, animations = [] }) {
  const [loadedImages, setLoadedImages] = useState();
  const [isShowing, setIsShowingState] = useState(false);
  const [currentDonation, setCurrentDonation] = useState(null);
  const [animationHeight, setAnimationHeight] = useState(DEFAULT_ANIMATION_HEIGHT);
  const donationsToShowRef = useRef([]);
  const isShowingRef = useRef(false);

  const setIsShowing = (val) => {
    setIsShowingState(val);
    isShowingRef.current = val;
  };

  const showDonation = async (donation) => {
    if (!donation.animation) {
      return;
    }

    if (isShowingRef.current) {
      donationsToShowRef.current = [ ...donationsToShowRef.current, donation ];
    } else {
      donationsToShowRef.current = donationsToShowRef.current.slice(1);
      const currentAnimation = animations.find(({ id }) => id === donation.animation);
      const duration = +(currentAnimation?.duration_in_ms || 4000)
      const animationHeight = +(currentAnimation?.height || DEFAULT_ANIMATION_HEIGHT);
      setAnimationHeight(animationHeight)
      setIsShowing(true);
      setCurrentDonation(donation);
      await wait(duration);
      setCurrentDonation(null);
      await wait(500);
      setIsShowing(false);
    }
  }

  const handleAnimationLoad = (name) => {
    setLoadedImages((prevState) => {
      const newState = {
        ...prevState,
        [name]: {
          loaded: true,
          url: AnimationService.getUrl(name),
        }
      }

      return newState;
    });
  }

  const preloadAnimations = async (names) => {
    for await (const name of names) {
      await wait(300);
      const animation = new Image();
      animation.onload = () => handleAnimationLoad(name);
      animation.src = AnimationService.getUrl(name);
    };
  };

  useWebSocket('widget', showDonation);

  useEffect(() => {
    if (!isShowing && donationsToShowRef.current.length) {
      showDonation(donationsToShowRef.current[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowing]);

  useEffect(() => {
    const animationNames = animations.map(({ id }) => id); 
    preloadAnimations(animationNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reloaded = sessionStorage.getItem("reloaded");

    if (!reloaded) {
      setTimeout(() => window.location.reload, 1000);
      sessionStorage.setItem("reloaded", true);
    } else {
      sessionStorage.setItem("reloaded", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DonationAlert donation={currentDonation} animations={loadedImages} height={animationHeight} />
  );
}

export default function DonationWidget() {
  return <Widget><DonationWidgetBase /></Widget>
}