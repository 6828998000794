export const DonationFolders = Object.freeze({
  NEW: "new", 
  READING: "reading",
  ARCHIVED: "archived"
});

export const DonationActionTypes = Object.freeze({
  SET_ALL_DONATIONS: "SET_ALL_DONATIONS",
  ADD_NEW_DONATION: "ADD_NEW_DONATION",
  UPDATE_DONATION: "MOVE_TO_READING_AS_VERIFIED",
  REMOVE_DONATION: "REMOVE_DONATION",
});

export const DonationSortingStates = Object.freeze({
  TIME_DESC: "TIME_DESC",
  TIME_DESC_ALT: "TIME_DESC_ALT",
  TIME_ASC: "TIME_ASC",
  AMOUNT_DESC: "AMOUNT_DESC",
});

export const DonationSourceNameMap = new Map([
  ["donatepay", "DonatePay"],
  ["btcpay", "BTC-Pay Bot"],
  ["crypto", "Cryptocurrency"],
  ["donationalerts", "Donation Alerts"],
  ["website", "Website"],
  ["admin", "Website"]
]);