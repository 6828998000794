import { BACKEND_URL, TOKEN } from "constants/index";

class AnimationService {
  static getUrl(animationName) {
    const url = `${BACKEND_URL}/api/animation/${animationName ? `${animationName}` : ""}?${new URLSearchParams({ access_token: TOKEN })}`;
    return url;
  }

  static async getAll() {
    try {
      const url = this.getUrl();
      const response = await fetch(url);
      const animations = await response.json();

      if (response.ok && animations?.length) {
        return animations;
      }
    } catch (err) {}

    return [];
  }

  static async create(name, duration, height, file) {
    try {
      const url = `${this.getUrl(name)}&${new URLSearchParams({
        duration_in_ms: duration,
        ...(height && { height }),
      })}`;

      const formData = new FormData();
      formData.append("animation_file", file);

      const response = await fetch(url, { 
        method: "POST", 
        body: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (response.ok) {
        const result = await response.json();
        return result;
      }
    } catch (err) {}

    return null;
  }

  static async getByName(name) {
    try {
      const url = this.getUrl(name);
      const response = await fetch(url);
      const animation = await response.json();

      if (response.ok && animation) {
        return animation;
      }
    } catch (err) {}

    return null;
  }

  static async removeByName(name) {
    try {
      const url = this.getUrl(name);
      const response = await fetch(url, { method: "DELETE" });
      return response.ok;
    } catch (err) {}

    return null;
  }
}

export default AnimationService;
