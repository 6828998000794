import { Routes, Route } from 'react-router-dom';

import Admin from "components/Admin";
import GoalWidget from "components/Widget/GoalWidget";
import DonationWidget from "components/Widget/DonationWidget";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/admin" element={<Admin />} />
        <Route path="/goal" element={<GoalWidget />} />
        <Route path="/donation" element={<DonationWidget />} />
      </Routes>
    </>
  );
}
