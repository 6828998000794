import GoalsSection from "components/Admin/SettingsBlock/GoalsSection"
import RulesSection from "components/Admin/SettingsBlock/RulesSection";
import AnimationsSection from "components/Admin/SettingsBlock/AnimationsSection";

import "./SettingsBlock.scss";

export default function SettingsBlock({ goals, setGoals, rules, setRules, animations, setAnimations }) {
  return (
    <div className="settingsBlock">
      <GoalsSection { ...{ goals, setGoals } } />
      <RulesSection { ...{ rules, setRules, animations } } />
      <AnimationsSection { ...{ animations, setAnimations } } />
    </div>
  );
}
