import { useEffect, useState, cloneElement, Children } from "react";

import TokenService from "services/TokenService";
import { TOKEN, GOAL_ID } from "constants/index";
import GoalService from "services/GoalService";
import AnimationService from "services/AnimationService";

import "./Widget.scss";

export default function Widget({ children }) {
  const [tokens, setTokens] = useState();
  const [goalId, setGoalId] = useState(null);
  const [goalCurrent, setGoalCurrent] = useState(null);
  const [goalMaxLimit, setGoalMaxLimit] = useState(null);
  const [goalName, setGoalName] = useState(null);
  const [animations, setAnimations] = useState([])

  const initGoalValues = ({ max_limit, name, current, id }) => {
    setGoalCurrent(current);
    setGoalMaxLimit(max_limit);
    setGoalName(name);
    setGoalId(id);
  };

  const updateGoal = ({ max_limit, name, current, amount_in_rub }) => {
    if (amount_in_rub) {
      setGoalCurrent(prevState => parseInt(prevState || 0) + parseInt(amount_in_rub));
    } else if (current) {
      setGoalCurrent(parseInt(current));
    }
    
    if (max_limit) {
      setGoalMaxLimit(parseInt(max_limit));
    }

    if (name) {
      setGoalName(name);
    }
  };

  useEffect(() => {
    async function getTokens() {
      if (TOKEN) {
        const tokens = await TokenService.getAll();
        setTokens(tokens);
      } else {
        setTokens(null);
      }
    }

    async function getGoal() {
      const goals = await GoalService.getAll();

      if (goals?.length) {
        const lastGoal = goals[goals.length - 1];
        const goal = (GOAL_ID && goals.find(({ id }) => id === GOAL_ID)) || lastGoal;
        initGoalValues(goal);
      }
    }

    async function getAnimations() {
      const animations = await AnimationService.getAll();
      setAnimations(animations);
    }

    async function loadAllData() {
      await getTokens();
      await getGoal();
      await getAnimations();
    }

    loadAllData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorPage = (<h1>Invalid token!</h1>);
  const checkPage = (<h1>Token verification...</h1>);

  if (tokens === undefined) {
    return checkPage;
  } else if (tokens === null) {
    return errorPage;
  } else if (!goalId) {
    console.warn("Goals not found!");
    return "";
  }

  const goal = {
    current: goalCurrent,
    max_limit: goalMaxLimit,
    name: goalName,
    id: goalId,
  };

  const childrenProps = { goal, updateGoal, animations };
  const childrenElement = Children.only(children);
  const currentWidget = cloneElement(childrenElement, childrenProps);
  const isReady = goal && animations.length;

  return (
    <div className="widget">
      { isReady ? currentWidget : ""}
    </div>
  );
}
