import "./GoalLine.scss";

export default function GoalLine({ title, maxLimit, current, currentTransitionMs }) {
  const currentPercentage = Math.floor(current / maxLimit * 100);

  const currentIndicatorStyles = {
    width: `${currentPercentage}%`,
    ...(currentTransitionMs && {
      background: '#ffffff66',
      transition: `all ${currentTransitionMs}ms ease-out`,
    }),
  };

  const formattedMaxLimit = new Intl.NumberFormat('ru-RU').format(parseInt(maxLimit));
  const formattedCurrent = new Intl.NumberFormat('ru-RU').format(parseInt(current));

  return (
    <div className="goalLine">
      <div className="goalLine-lineBox">
        <div className="goalLine-subscribeBanner" />
        <div className="goalLine-line">
          <div className="goalLine-currentIndicator" style={currentIndicatorStyles} />
          <div className="goalLine-sums">
            <h3 className="goalLine-sum goalLine-sum_start">0 ₽</h3>
            <h3 className="goalLine-sum goalLine-sum_title">{title}</h3>
            <div className="goalLine-sum goalLine-sum_current">
              <h3>{formattedCurrent} ₽</h3>
            </div>
            <h3 className="goalLine-sum goalLine-sum_max">{formattedMaxLimit} ₽</h3>
          </div>
        </div>
      </div>
    </div>
  );
}