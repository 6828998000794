import Button from "@mui/material/Button";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';

import AnimationService from "services/AnimationService";
import AnimationUploadModal from "components/Admin/SettingsBlock/AnimationsSection/AnimationUploadModal";
import { DEFAULT_ANIMATION_HEIGHT } from "constants/index";

export default function AnimationsSection({ animations, setAnimations }) {
  const [animationModalState, setAnimationModalState] = useState({ isOpen: false });

  const createAnimation = async ({ name, duration, file, height }) => {
    const result = await AnimationService.create(name, duration, height, file);
    
    if (result) {
      setAnimations((state) => [ ...state, { id: name, duration_in_ms: duration, height } ]);
    }

    return result || null;
  };

  const removeAnimation = async (name) => {
    const result = await AnimationService.removeByName(name);
    
    if (result) {
      setAnimations((state) => state.filter(({ id }) => id !== name));
    }

    return result || null;
  }

  const openAnimationModal = () => {
    setAnimationModalState({ isOpen: true });
  };

  const closeAnimationModal = () => {
    setAnimationModalState({ isOpen: false });
  };

  const animationModalProps = {
    isOpen: animationModalState.isOpen,
    close: closeAnimationModal,
    submit: createAnimation,
  }

  const animationsComponents = animations.map(animation => (
    <div className="settingsItem settingsItem_animation" key={animation.id}>
      <div className="settingsItem-info">
        <div className="settingsItem-labels">
          <p>Name (id):</p>
          <p>Duration:</p>
          <p>Height:</p>
        </div>
        <div className="settingsItem-values">
          <p>{animation.id}</p>
          <p>{animation.duration_in_ms}</p>
          <p>{animation?.height || DEFAULT_ANIMATION_HEIGHT}</p>
        </div>
      </div>
      <div className="settingsItem-editButton">
        <Button 
          onClick={() => {}}
          disabled={true}
          variant="outlined" 
          color={"gold"}
          endIcon={<EditNoteIcon />}
        >
          Edit animation
        </Button>
      </div>
      <div className="settingsItem-removeButton">
        <Button 
          onClick={() => removeAnimation(animation.id)}
          variant="outlined"
          color={"red"}
          endIcon={<DeleteForeverIcon />}
        >
          Remove animation
        </Button>
      </div>
    </div>
  ));

  return (
    <div className="settingsSection settingsSection_rules">
      <h2 className="settingsSection-title">
        Animations
        <IconButton
          onClick={() => openAnimationModal()}
          color="seagreen"
          className="settingsSection-addButton"
          size="small"
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </h2>
      <div className="settingsSection-items">
        { animationsComponents }
      </div>
      <AnimationUploadModal { ...animationModalProps } />
    </div>
  );
}
